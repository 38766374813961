import React from "react";
import Router from "./shared/Router";
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  return (
    <>   
    <GlobalStyles/>
    <Router/>
    </>
  )

}

export default App;